// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-au-js": () => import("./../../../src/pages/about/au.js" /* webpackChunkName: "component---src-pages-about-au-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-nz-js": () => import("./../../../src/pages/about/nz.js" /* webpackChunkName: "component---src-pages-about-nz-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-embed-shopify-dashboard-js": () => import("./../../../src/pages/embed/shopify/dashboard.js" /* webpackChunkName: "component---src-pages-embed-shopify-dashboard-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-merchant-analytics-index-js": () => import("./../../../src/pages/merchant/analytics/index.js" /* webpackChunkName: "component---src-pages-merchant-analytics-index-js" */),
  "component---src-pages-merchant-home-index-js": () => import("./../../../src/pages/merchant/home/index.js" /* webpackChunkName: "component---src-pages-merchant-home-index-js" */),
  "component---src-pages-merchant-index-js": () => import("./../../../src/pages/merchant/index.js" /* webpackChunkName: "component---src-pages-merchant-index-js" */),
  "component---src-pages-merchant-login-callback-js": () => import("./../../../src/pages/merchant/login/callback.js" /* webpackChunkName: "component---src-pages-merchant-login-callback-js" */),
  "component---src-pages-merchant-login-index-js": () => import("./../../../src/pages/merchant/login/index.js" /* webpackChunkName: "component---src-pages-merchant-login-index-js" */),
  "component---src-pages-merchant-logout-js": () => import("./../../../src/pages/merchant/logout.js" /* webpackChunkName: "component---src-pages-merchant-logout-js" */),
  "component---src-pages-merchant-orders-create-js": () => import("./../../../src/pages/merchant/orders/create.js" /* webpackChunkName: "component---src-pages-merchant-orders-create-js" */),
  "component---src-pages-merchant-orders-dashboard-js": () => import("./../../../src/pages/merchant/orders/dashboard.js" /* webpackChunkName: "component---src-pages-merchant-orders-dashboard-js" */),
  "component---src-pages-merchant-orders-review-js": () => import("./../../../src/pages/merchant/orders/review.js" /* webpackChunkName: "component---src-pages-merchant-orders-review-js" */),
  "component---src-pages-merchant-orders-summary-js": () => import("./../../../src/pages/merchant/orders/summary.js" /* webpackChunkName: "component---src-pages-merchant-orders-summary-js" */),
  "component---src-pages-merchant-pos-initiate-js": () => import("./../../../src/pages/merchant/pos/initiate.js" /* webpackChunkName: "component---src-pages-merchant-pos-initiate-js" */),
  "component---src-pages-merchant-pos-orders-summary-js": () => import("./../../../src/pages/merchant/pos/orders/summary.js" /* webpackChunkName: "component---src-pages-merchant-pos-orders-summary-js" */),
  "component---src-pages-merchant-reports-index-js": () => import("./../../../src/pages/merchant/reports/index.js" /* webpackChunkName: "component---src-pages-merchant-reports-index-js" */),
  "component---src-pages-merchant-selectmerchant-index-js": () => import("./../../../src/pages/merchant/selectmerchant/index.js" /* webpackChunkName: "component---src-pages-merchant-selectmerchant-index-js" */),
  "component---src-pages-merchant-statements-graphs-js": () => import("./../../../src/pages/merchant/statements/graphs.js" /* webpackChunkName: "component---src-pages-merchant-statements-graphs-js" */),
  "component---src-pages-merchant-statements-settlementsummary-js": () => import("./../../../src/pages/merchant/statements/settlementsummary.js" /* webpackChunkName: "component---src-pages-merchant-statements-settlementsummary-js" */),
  "component---src-pages-merchant-trainingguides-index-js": () => import("./../../../src/pages/merchant/trainingguides/index.js" /* webpackChunkName: "component---src-pages-merchant-trainingguides-index-js" */),
  "component---src-pages-outcome-js": () => import("./../../../src/pages/outcome.js" /* webpackChunkName: "component---src-pages-outcome-js" */),
  "component---src-pages-payment-complete-js": () => import("./../../../src/pages/payment/complete.js" /* webpackChunkName: "component---src-pages-payment-complete-js" */),
  "component---src-pages-playground-checkout-js": () => import("./../../../src/pages/playground/checkout.js" /* webpackChunkName: "component---src-pages-playground-checkout-js" */),
  "component---src-pages-playground-widget-js": () => import("./../../../src/pages/playground/widget.js" /* webpackChunkName: "component---src-pages-playground-widget-js" */),
  "component---src-pages-playground-widget-testing-js": () => import("./../../../src/pages/playground/widget-testing.js" /* webpackChunkName: "component---src-pages-playground-widget-testing-js" */),
  "component---src-pages-points-checkout-index-js": () => import("./../../../src/pages/points/checkout/index.js" /* webpackChunkName: "component---src-pages-points-checkout-index-js" */),
  "component---src-pages-points-index-js": () => import("./../../../src/pages/points/index.js" /* webpackChunkName: "component---src-pages-points-index-js" */),
  "component---src-pages-points-login-callback-js": () => import("./../../../src/pages/points/login/callback.js" /* webpackChunkName: "component---src-pages-points-login-callback-js" */),
  "component---src-pages-points-login-index-js": () => import("./../../../src/pages/points/login/index.js" /* webpackChunkName: "component---src-pages-points-login-index-js" */),
  "component---src-pages-purchase-initiate-js": () => import("./../../../src/pages/purchase/initiate.js" /* webpackChunkName: "component---src-pages-purchase-initiate-js" */),
  "component---src-pages-rewards-index-js": () => import("./../../../src/pages/rewards/index.js" /* webpackChunkName: "component---src-pages-rewards-index-js" */),
  "component---src-pages-rewards-link-index-js": () => import("./../../../src/pages/rewards/link/index.js" /* webpackChunkName: "component---src-pages-rewards-link-index-js" */),
  "component---src-pages-rewards-login-callback-js": () => import("./../../../src/pages/rewards/login/callback.js" /* webpackChunkName: "component---src-pages-rewards-login-callback-js" */),
  "component---src-pages-rewards-login-index-js": () => import("./../../../src/pages/rewards/login/index.js" /* webpackChunkName: "component---src-pages-rewards-login-index-js" */),
  "component---src-pages-shopify-about-js": () => import("./../../../src/pages/shopify/about.js" /* webpackChunkName: "component---src-pages-shopify-about-js" */),
  "component---src-pages-shopify-auth-js": () => import("./../../../src/pages/shopify/auth.js" /* webpackChunkName: "component---src-pages-shopify-auth-js" */),
  "component---src-pages-shopify-install-js": () => import("./../../../src/pages/shopify/install.js" /* webpackChunkName: "component---src-pages-shopify-install-js" */),
  "component---src-pages-shopify-payment-index-js": () => import("./../../../src/pages/shopify/payment/index.js" /* webpackChunkName: "component---src-pages-shopify-payment-index-js" */),
  "component---src-pages-shopify-payment-outcome-js": () => import("./../../../src/pages/shopify/payment/outcome.js" /* webpackChunkName: "component---src-pages-shopify-payment-outcome-js" */),
  "component---src-pages-shopify-payment-settings-js": () => import("./../../../src/pages/shopify/payment/settings.js" /* webpackChunkName: "component---src-pages-shopify-payment-settings-js" */),
  "component---src-pages-wallet-checkout-index-js": () => import("./../../../src/pages/wallet/checkout/index.js" /* webpackChunkName: "component---src-pages-wallet-checkout-index-js" */),
  "component---src-pages-wallet-index-js": () => import("./../../../src/pages/wallet/index.js" /* webpackChunkName: "component---src-pages-wallet-index-js" */),
  "component---src-pages-wallet-login-callback-js": () => import("./../../../src/pages/wallet/login/callback.js" /* webpackChunkName: "component---src-pages-wallet-login-callback-js" */),
  "component---src-pages-wallet-login-index-js": () => import("./../../../src/pages/wallet/login/index.js" /* webpackChunkName: "component---src-pages-wallet-login-index-js" */),
  "component---src-pages-wallet-logout-js": () => import("./../../../src/pages/wallet/logout.js" /* webpackChunkName: "component---src-pages-wallet-logout-js" */)
}

